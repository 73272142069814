import { useRef } from 'react';

import { useWindowSize } from './useWindowSize';

/**
 * Returns the innerHeight of the window in pixels eg: '659px'
 */
export const use100vh = () => {
  const ref = useRef<string>('');

  const { height } = useWindowSize();
  ref.current = `${height}px`;

  return ref.current;
};
